<template>
    <div>
        <div class="home-bg1">
            <div class="cycle-wrapper">
                <div class="breadcrumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>组态模块</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div>
                    <configuration></configuration>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Configuration from '../../components/product/Configuration'
export default {
    components: {
        Configuration,
    },
    data() {
        return {

        }
    },
    methods: {
        setSize1: function() {
            var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.screenWidth3 = width;
            //图片                高 / 宽  500 / 1920
            this.bannerHeight3 = 600 / 1920 * this.screenWidth3 - 50
            document.getElementById('el-carousel3').style.height = this.bannerHeight3 + 'px';
        },
        setSize: function() {
            this.bannerHeight3 = 600 / 1920 * this.screenWidth3 - 50
            document.getElementById('el-carousel3').style.height = this.bannerHeight3 + 'px';
        },
    },
    mounted() {
        this.setSize1();
        const that = this;
        //监听浏览器窗口大小改变
        window.addEventListener('resize', function() {
            var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            that.screenWidth3 = width;
            that.setSize();
        }, false);
    }
}
</script>
<style lang="less" scoped>
// web端样式
.home-bg1 {
    user-select: none;
    width: 100%;
    height: 100vh;
    margin-top: -90px;
    background-image: url('../../img/bg1.png');
    background-size: cover;
    position: relative;
    overflow: auto;
    .cycle-wrapper {
        padding: 90px 30px 0;
        color: #FFFFFF;
    }
}

// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
        }
    }
}
</style>
<style>
/* web端样式 */
.el-carousel__container {
    height: 100% !important;
}

/* 移动端样式 */
@media screen and (max-width:650px) {
    .cycle-carousel3 {
        height: 200px !important;
    }
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>